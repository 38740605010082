import React from "react"
import TwitterIcon from "@mui/icons-material/Twitter"
import BasicPopover from "./popover"
// import LinkedInIcon from "@mui/icons-material/LinkedIn"
// import FacebookIcon from "@mui/icons-material/Facebook"

export default function SocialShares({ urlBlog }) {
  const url = `https://twitter.com/intent/tweet?url=${urlBlog}`
  return (
    <div
      style={{
        paddingbottom: "40px",
        paddingTop: "20px",
        textAlign: "center",
        textJustify: "inter-word",
      }}
    >
      <span style={{ marginLeft: "10px" }}>
        <a href={url} style={{ color: "#FF0063" }}>
          <TwitterIcon fontSize="large" />
        </a>
      </span>
      {/* <BasicPopover /> */}
      {/* <span style={{ marginLeft: "10px" }}>
        <LinkedInIcon fontSize="large" />
      </span>
      <span style={{ marginLeft: "10px" }}>
        <FacebookIcon fontSize="large" />
      </span> */}
    </div>
  )
}
