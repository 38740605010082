import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import MailChimpForm from "../components/Mailchimpform"
import SEO from "../components/seo"
import PopUp from "../components/popup"
import { Link } from "gatsby"
import Typography from "@mui/material/Typography"
import SocialShares from "./socialshares"

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  const [ShowPopUp, setPopUp] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setPopUp(true)
    }, 60000)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setPopUp(true)
    }, 180000)
  }, [])

  return (
    <Layout pageName="home" pageLink="/">
      <SEO
        description={post.frontmatter.desc}
        image={post.frontmatter.image_link}
        url={`https://www.thetechoasis.com${post.frontmatter.slug}`}
        titleTag={post.frontmatter.title_tag}
      />
      <div
        style={{
          textAlign: "justify",
          textJustify: "inter-word",
          maxWidth: "800px",
          margin: "0 auto",
          paddingTop: "20px",
          paddingBottom: "40px",
          paddingLeft: "5%",
          paddingRight: "5%",
          fontSize: "16.5px",
        }}
      >
        <PopUp trigger={ShowPopUp} setTrigger={setPopUp} />
        <h1>{post.frontmatter.title}</h1>
        <div
          style={{ color: "black" }}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <MailChimpForm />
        <Link to="/how-can-blockchain-be-used-to-support-sustainable-business-practices/">
          <Typography
            textAlign="center"
            sx={{
              mr: 2,
              ml: 2,
              mb: 1,
              mt: 3,
              display: { xs: "flex", md: "flex" },
              fontFamily: "Helvetica",
              fontWeight: 700,
              fontSize: 20,
              letterSpacing: ".1rem",
              textDecoration: "none",
            }}
          >
            Check out my latest blog!
          </Typography>
        </Link>
        <SocialShares
          urlBlog={`https://www.thetechoasis.com${post.frontmatter.slug}`}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        title_tag
        desc
        slug
        image_link
      }
    }
  }
`
