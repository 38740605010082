import React from "react"
import MailChimpForm from "../components/Mailchimpform"
import Image from "../components/image"
import CloseIcon from "@mui/icons-material/Close"
import "./popup.css"
import { fontWeight } from "@mui/system"

const PopUp = ({ trigger, setTrigger }) => {
  return (
    trigger && (
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          backgroundColor: "#D8D8D8",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1",
          opacity: 0.97,
        }}
      >
        <div
          style={{
            position: "relative",
            padding: "32px",
            width: "80%",
            maxWidth: "640px",
            backgroundColor: "white",
            opacity: 1,
          }}
          class="popup"
        >
          <div style={{ position: "absolute", right: 18, top: 18 }}>
            <CloseIcon
              onClick={() => {
                setTrigger(false)
              }}
              color="disabled"
            />
          </div>
          <div
            style={{
              width: "40%",
              minWidth: "180px",
              margin: "auto",
              position: "absolute",
              top: 5,
              left: 3,
            }}
          >
            <Image />
          </div>
          <div
            style={{
              fontSize: 34,
              marginBottom: "10px",
              fontWeight: 5,
              marginTop: "30px",
            }}
          >
            <span style={{ color: "#FF0063" }}>Become</span> aware.{" "}
            <span style={{ color: "#FF0063" }}>Be</span> ready.
          </div>
          <div style={{ marginBottom: "20px", color: "black", fontSize: 18 }}>
            If you're{" "}
            <span style={{ color: "#FF0063", fontWeight: 10 }}>enjoying</span>{" "}
            blog posts like this one, you are going to{" "}
            <span style={{ color: "#FF0063", fontWeight: 10 }}>love</span> my
            weekly free newsletter.{" "}
            <div style={{ fontSize: 24, marginTop: 8, font: "bold" }}>
              Subscribe <span style={{ color: "#FF0063" }}>now</span>!
            </div>
          </div>
          <MailChimpForm widthMinima="200px" />
        </div>
      </div>
    )
  )
}

export default PopUp
